<template>
  <div class="app-main-content">
    <card>
      <template #tools>
        <div class="title">项目统计表</div>
      </template>
      <div class="main">
        <div class="table">
          <a-table
              :data-source="list"
              rowKey="id"
              bordered
              :pagination="false"
          >
            <a-table-column title="项目名称" data-index="project_name"></a-table-column>
            <a-table-column title="负责人" width="200px">
              <template v-slot:default="row">
                <div class="edp-title">
                  <a-avatar :size="16" :src="row.pic"/>
                  <span>{{ row.title ? row.name + '-' + row.title : row.name }}</span>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="任务统计" width="121px">
              <template v-slot:default="row">
                <span class="blue">{{row.issue_notstarted}}</span>
                <span>/</span>
                <span class="yellow">{{row.issue_running}}</span>
                <span>/</span>
                <span class="green">{{row.issue_finish}}</span>
              </template>
            </a-table-column>
            <a-table-column title="任务完成度" width="151px">
              <template v-slot:default="row">
                <span>{{row.issue_finish_rate}}%</span>
              </template>
            </a-table-column>
            <a-table-column title="产品交付" width="201px">
              <template v-slot:default="row">
                <span class="yellow">{{row.product_actual_count}}</span>
                <span>/</span>
                <span class="green">{{row.product_schedule_count}}</span>
              </template>
            </a-table-column>
            <a-table-column title="产品交付完成度" width="201px">
              <template v-slot:default="row">
                <span>{{row.product_finish_rate}}%</span>
              </template>
            </a-table-column>
            <a-table-column title="实际收入/计划收入" width="201px">
              <template v-slot:default="row">
                <span class="yellow">{{ row.actual_income | numberFormat }}</span>
                <span>/</span>
                <span class="green">{{ row.schedule_income | numberFormat }}</span>
              </template>
            </a-table-column>
            <a-table-column title="收入占比" width="151px">
              <template v-slot:default="row">
                {{row.income_rate}}%
              </template>
            </a-table-column>
            <a-table-column title="实际支出/计划支出" width="201px">
              <template v-slot:default="row">
                <span class="yellow">{{row.actual_outcome | numberFormat}}</span>
                <span>/</span>
                <span class="green">{{row.schedule_outcome | numberFormat}}</span>
              </template>
            </a-table-column>
            <a-table-column title="支出占比" width="150px">
              <template v-slot:default="row">
                {{row.outcome_rate}}%
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {fetch} from "@/utils/request";

export default {
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const res = await fetch("post", "/sta/project", {})
      if (res.status === 0) {
        console.log(res);
        this.list = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  /deep/ .edp-card {
    .edp-card-head {
      display: none;
    }

    .edp-card-tools {
      padding: 30px;
      min-height: 32px;

      .title {
        font-weight: 500;
        font-size: 22px;
        color: #4D4D4D;
      }
    }

    .edp-card-body {
      .main {
        padding: 0 30px;

        .head {
          margin-bottom: 30px;

          .title {
            font-size: 22px;
            font-weight: 500;
            color: #4D4D4D;
          }

          .right-info{

            >.line{
              line-height: 27px;
              height: 27px;
              width: 1px;
              background-color: #CACACA;
              margin: 0 15px;
            }

            >div{
              line-height: 27px;
            }
          }
        }

        .table {
          .ant-table {
            .ant-table-thead {
              > tr {
                > th {
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 18px;
                  height: 18px;
                  color: #4D4D4D;
                  padding: 16px;
                  border-color: transparent;
                  background: #f8f8f8;

                  &:nth-last-child(1){
                    border-color: #EDEDED;
                  }
                }
              }
            }
            .ant-table-tbody{
              tr{
                td{
                  font-weight: 400;
                  height: 16px;
                  line-height: 16px;
                  font-size: 16px;
                  color: #4D4D4D;
                  padding: 16px;
                  border-right-color: transparent;

                  .edp-title{
                    font-size: 16px;

                    span{
                      display: inline-block;
                      margin-right: 5px;
                      vertical-align: middle;
                      height: 16px;
                      font-size: 16px;
                    }
                  }

                  .edp-owners{
                    display: inline-block;
                  }

                  .blue{
                    color: #0088FF;
                  }

                  .yellow{
                    color: #FCBD26;
                  }

                  .green{
                    color: #17C665;
                  }

                  &:nth-last-child(1){
                    border-right-color: #EDEDED;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
